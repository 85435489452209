import React, { FC } from 'react';
import { Card, CardHeader, CardContent, Typography } from '@material-ui/core';
import { CardHeaderTitle, CodeBlock, Title } from '.';

export const LearnCustomSequence: FC = (props) =>
{
    return (
        <>
            <Card>
                <CardHeader
                    title={<CardHeaderTitle>Custom sequence</CardHeaderTitle>}
                />
                <CardContent>
                    <Title>1. Define custom component</Title>
                    <CodeBlock>
{`using Unity.Entities;

public struct CustomComponent : IComponentData
{
    public int Value;
}`}
                    </CodeBlock>
                    <br />
                    <Title>2. Define custom tween component</Title>
                    <CodeBlock>
                        {`using System.Runtime.CompilerServices;
using ECSTween;
using Unity.Mathematics;

public struct TweenCustom : ITween<CustomComponent, int>
{
    public TweenData<int> Value { get; set; }

    [MethodImpl(MethodImplOptions.AggressiveInlining)]   
    public CustomComponent Mutate(CustomComponent target, int from, int to, float lerp)
    {
        return new CustomComponent
        {
            Value = (int)math.lerp(from, to, lerp)
        };
    }
}`}
                    </CodeBlock><br />
                    <Title>3. Define custom tween system</Title>
                    <CodeBlock>
{`using ECSTween;

public class TweenCustomSystem : TweenSystem<TweenCustom, CustomComponent, int> {}`}
                    </CodeBlock>
                    <br />
                    <Title>4. Define custom tween buffer element</Title>
                    <CodeBlock>
{`using ECSTween;

public struct TweenCustomBufferElement : ITweenBufferElement<CustomComponent, int>
{
    public TweenBufferData<int> Value { get; set; }
}`}
                    </CodeBlock>
                    <br />
                    <Title>5. Define custom sequence system</Title>
                    <CodeBlock>
{`using ECSTween;

public class SequenceCustomSystem : SequenceSystem<TweenCustomBufferElement, TweenCustom, CustomComponent, int> {}`}
                    </CodeBlock>
                    <br />
                    <Title>6. Use custom sequence</Title>
                    <CodeBlock>
{`using ECSTween;
using Unity.Entities;
using UnityEngine;

public class Bootstrap : MonoBehaviour
{
    private void Awake()
    {
        var entityManager = World.Active.EntityManager;

        var archetype = entityManager.CreateArchetype(
            typeof(CustomComponent),
            typeof(TweenCustom),
            typeof(SequenceCustom)
        );
        
        var entity = entityManager.CreateEntity(archetype);
        
        var entityBuffer = entityManager.AddBuffer<TweenCustomBufferElement>(entity);
        for(var i = 0; i < 10; i++)
        {
            entityBuffer.Add(new TweenCustomBufferElement {...});
        }

        var sequenceCustomComponent = new SequenceCustom {...};
        entityManager.SetComponentData(entity, sequenceCustomComponent);
    }
}`}
                    </CodeBlock>
                </CardContent>
            </Card>
        </>
    );
}