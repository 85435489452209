import React, { FC } from 'react';
import { Switch, Route } from "react-router-dom";
import { GettingStarted, Home, Docs } from '.';

export const Routes: FC = () =>
{
    return (
        <Switch>
            <Route exact path="/">
                <Home />
            </Route>
            <Route path="/docs">
                <Docs />
            </Route>
        </Switch>
    );
}