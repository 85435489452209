import React, { FC } from 'react';
import { ThemeProvider } from ".";

export const Providers: FC = (props) =>
{
	return (
		<ThemeProvider>
			{props.children}
		</ThemeProvider>
	);
}