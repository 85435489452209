import React, { FC } from 'react';
import { BrowserRouter as Router } from "react-router-dom";
import { Providers, Layout, Routes } from '.';

export const App: FC = () =>
{
	return (
		<Providers>
			<Router>
				<Layout>
					<Routes />
				</Layout>
			</Router>
		</Providers>
	);
}