import React, { FC } from 'react';
import { AppBar, Toolbar, Button, Hidden, IconButton } from '@material-ui/core';
import { useStyles } from "./Layout.styles";
import { Link, NavLink } from 'react-router-dom';
import Home from "@material-ui/icons/Home";
import Email from "@material-ui/icons/Email";
import Description from "@material-ui/icons/Description";

export const Layout: FC = (props) =>
{
	const classes = useStyles();

	return (
		<>
			<AppBar color="default" position="fixed">
				<Toolbar>
					<Hidden mdUp>
						<IconButton to="/" exact component={NavLink}>
							<Home />
						</IconButton>
						<IconButton to="/docs" component={NavLink}>
							<Description />
						</IconButton>
						<IconButton href="mailto:ecs-tween@arkuni.com">
							<Email />
						</IconButton>
					</Hidden>
					<Hidden smDown>
						<Button
							to="/"
							activeClassName=""
							component={NavLink}
							startIcon={<Home />}
							className={classes.logo}
						>
							ECS Tween
						</Button>
						<Button
							to="/docs"
							component={NavLink}
							startIcon={<Description />}
							className={classes.button}
						>
							Docs
						</Button>
						<Button
							href="mailto:ecs-tween@arkuni.com"
							startIcon={<Email />}
							className={classes.button}
						>
							Contact
						</Button>
					</Hidden>
				</Toolbar>
			</AppBar>
			<Toolbar />
			{props.children}
		</>
	);
}