import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) => ({
    root: {
        padding: theme.spacing(2),

        [theme.breakpoints.up('md')]: {
            padding: theme.spacing(3),
        },
        
        [theme.breakpoints.up('lg')]: {
            padding: theme.spacing(4),
        },
    },
}))