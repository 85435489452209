import React, { FC } from "react";
import { Theme, Typography } from "@material-ui/core";

export const Title: FC = (props) => 
{
  return (
    <Typography gutterBottom>
      <b>
        {props.children}
      </b>
    </Typography>
  );
}