import React, { FC } from 'react';
import { Card, CardHeader, CardContent, Typography } from '@material-ui/core';
import { CardHeaderTitle, CodeBlock, Title } from '.';

export const LearnCustomShake: FC = (props) =>
{
    return (
        <>
            <Card>
                <CardHeader
                    title={<CardHeaderTitle>Custom shake</CardHeaderTitle>}
                />
                <CardContent>
                    <Title>1. Define custom component</Title>
                    <CodeBlock>
                        {`using Unity.Entities;

public struct CustomComponent : IComponentData
{
    public int Value;
}`}
                    </CodeBlock>
                    <br />
                    <Title>2. Define custom tween component</Title>
                    <CodeBlock>
{`using System.Runtime.CompilerServices;
using ECSTween;
using Unity.Mathematics;

public struct CustomShake : IShake<CustomComponent, int>
{
    public ShakeData<int> Value { get; set; }

    [MethodImpl(MethodImplOptions.AggressiveInlining)]
    public int Random(int at, int strength, int random, float lerp)
    {
        return ShakeUtils.RandomInt(at, strength, random / 100f, lerp);
    }

    [MethodImpl(MethodImplOptions.AggressiveInlining)]
    public CustomComponent Mutate(CustomComponent target, int from, int to, float lerp)
    {
        return new CustomComponent
        {
            Value = (int)math.lerp(from, to, lerp)
        };
    }
}`}
                    </CodeBlock>
                    <br />
                    <Title>3. Define custom tween system</Title>
                    <CodeBlock>
{`using ECSTween;

public class CustomShakeSystem : ShakeSystem<CustomShake, CustomComponent, int>
{
    protected override int GetRandom()
    {
        return Randomizer.NextInt(0, 100);
    }
}
`}
                    </CodeBlock>
                    <br />
                    <Title>4. Use custom shake</Title>
                    <CodeBlock>
{`using Unity.Entities;
using UnityEngine;

public class Bootstrap : MonoBehaviour
{
    private void Awake()
    {
        var entityManager = World.Active.EntityManager;

        var archetype = entityManager.CreateArchetype(
            typeof(CustomComponent),
            typeof(CustomShake),
        );
        
        var entity = entityManager.CreateEntity(archetype);
        entityManager.SetComponentData(entity, new CustomShake
        {
            Value = new ShakeData<int>{...}
        });
    }
}`}
                    </CodeBlock>
                </CardContent>
            </Card>
        </>
    );
}