import { Box, Drawer, List, ListItem, Toolbar } from '@material-ui/core';
import React, { FC } from 'react';
import { NavLink, Route, Switch, useRouteMatch } from "react-router-dom";
import { Content, GettingStarted, LearnCustomShake, LearnCustomTween, LearnPunch, LearnShakes, LearnTween, LearnControl, LearnSequence, LearnCustomSequence } from '.';

export const Docs: FC = () =>
{
	const match = useRouteMatch();

	if (!match)
		return <></>;

	return (
		<>
			<Drawer open variant="permanent">
				<Toolbar />
				<List>
					<ListItem button component={NavLink} to={match.url} exact>
						Getting started
					</ListItem>
					<ListItem button component={NavLink} to={match.url + "/tween"}>
						Tween
					</ListItem>
					<ListItem button component={NavLink} to={match.url + "/punch"}>
						Punch
					</ListItem>
					<ListItem button component={NavLink} to={match.url + "/shake"}>
						Shake
					</ListItem>
					<ListItem button component={NavLink} to={match.url + "/sequence"}>
						Sequence
					</ListItem>
					<ListItem button component={NavLink} to={match.url + "/control"}>
						Control
					</ListItem>
					<ListItem button component={NavLink} to={match.url + "/custom-tween"}>
						Custom tween
					</ListItem>
					<ListItem button component={NavLink} to={match.url + "/custom-shake"}>
						Custom shake
					</ListItem>
					<ListItem button component={NavLink} to={match.url + "/custom-sequence"}>
						Custom sequence
					</ListItem>
				</List>
			</Drawer>
			<Box ml="147px">
				<Content>
					<Switch>
						<Route path={match.url + "/"} exact>
							<GettingStarted />
						</Route>
						<Route path={match.url + "/tween"}>
							<LearnTween />
						</Route>
						<Route path={match.url + "/punch"}>
							<LearnPunch />
						</Route>
						<Route path={match.url + "/shake"}>
							<LearnShakes />
						</Route>
						<Route path={match.url + "/sequence"}>
							<LearnSequence />
						</Route>
						<Route path={match.url + "/control"}>
							<LearnControl />
						</Route>
						<Route path={match.url + "/custom-tween"}>
							<LearnCustomTween />
						</Route>
						<Route path={match.url + "/custom-shake"}>
							<LearnCustomShake />
						</Route>
						<Route path={match.url + "/custom-sequence"}>
							<LearnCustomSequence />
						</Route>
					</Switch>
				</Content>
			</Box>
		</>
	);
}