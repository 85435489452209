import React, { FC } from "react";
import { Theme, Typography } from "@material-ui/core";

export const CardHeaderTitle: FC = (props) => 
{
    return (
      <Typography variant="h5">
          {props.children}
      </Typography>
    );
}