import React, { FC } from 'react';
import { Card, CardHeader, CardContent, Typography } from '@material-ui/core';
import { CardHeaderTitle, CodeBlock, Title } from '.';

export const LearnControl: FC = (props) =>
{
    return (
        <>
            <Card>
                <CardHeader
                    title={<CardHeaderTitle>Control</CardHeaderTitle>}
                />
                <CardContent>
                    <Title>Pause</Title>
                    <Typography gutterBottom>Add the PauseTag component to pause all tweens, shakes and sequences of an entity.</Typography>
                    <CodeBlock>
                        {`entityManager.AddComponentData(entity, new PauseTag());`}
                    </CodeBlock>
                </CardContent>
            </Card>
        </>
    );
}