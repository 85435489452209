export interface CodeTemplate 
{
    title: string;
    component: string;
    valueType: string;
}

export const supportedComponents: CodeTemplate[] = [
    {
        title: "Translation",
        component: "Translation",
        valueType: "float3"
    },
    {
        title: "Scale",
        component: "Scale",
        valueType: "float",
    },
    {
        title: "NonUniformScale",
        component: "NonUniformScale",
        valueType: "float3",
    },
    {
        title: "Rotation",
        component: "Rotation",
        valueType: "quaternion"
    }
];