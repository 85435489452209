import React, { FC } from 'react';
import { useStyles } from './Content.styles';

export const Content: FC = (props) =>
{
	const classes = useStyles();

    return (
        <div className={classes.root}>
            {props.children}
        </div>
    );
}