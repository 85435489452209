import { Card, CardHeader, Typography, CardContent } from '@material-ui/core';
import React, { FC } from 'react';
import { CodeSample, CardHeaderTitle, CodeSamplesProps, CodeSamples } from '.';
import { supportedComponents } from '../data';

const samples = supportedComponents.map<CodeSample>(t => ({
    title: t.title,
    snippet: `var tween${t.component}Component = Create.${t.component}Tween(...);`,
    code: (
        `Tween${t.component} tween = Create.${t.component}Tween(
    ${t.valueType} from, // Starting value
    ${t.valueType} to, // End value
    float duration, // Duration in seconds
    Ease ease, // Easing function
    int loops, // Times to play the tween (use negative value for infinite loop)
    LoopMode loopMode, // Direction of the each loop (normal, reverse, ping-pong or reverse-ping-pong)
    float delay, // Time in seconds before the tweening begins in each loop
    float delayAfter // Time in seconds to wait after the tween in each loop
));`
    ),
    example: (
        `using ECSTween;
using Unity.Entities;
using Unity.Transforms;
using UnityEngine;

public class Bootstrap : MonoBehaviour
{
    private void Awake()
    {
        var entityManager = World.Active.EntityManager;

        var archetype = entityManager.CreateArchetype(
            typeof(${t.component}),
            typeof(Tween${t.component})
        );
        
        var entity = entityManager.CreateEntity(archetype);

        var tween${t.component}Component = Create.${t.component}Tween(...);
        
        entityManager.SetComponentData(entity, tween${t.component}Component);
    }
}`
    )
}));

export const LearnTween: FC = (props) =>
{
    return (
        <>
            <Card>
                <CardHeader
                    title={<CardHeaderTitle>Tween</CardHeaderTitle>}
                />
                <CardContent>
                    <Typography>
                        To tween the value of any component on an entity, add the corresponding tween component to the same entity.
                    </Typography>
                </CardContent>
                <CodeSamples samples={samples} />
            </Card>
        </>
    );
}