import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) => ({
    logo: {
        fontSize: "1.125rem",
        marginRight: theme.spacing(4),
    },
    button: {
        marginRight: theme.spacing(2),
    },
}))