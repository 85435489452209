import { Box, Button, ButtonGroup, Card, CardContent, CardHeader, Container, Grid, List, ListItem, Typography } from '@material-ui/core';
import Build from "@material-ui/icons/Build";
import Star from "@material-ui/icons/Star";
import React, { FC } from 'react';
import { NavLink } from 'react-router-dom';
import { CardHeaderTitle, CodeBlock, Content } from '.';
import Splash from '../assets/Splash.png';
import { Title } from './Title';

const features: { title: string, text: string }[] = [
    {
        title: "Modern",
        text: "Compatible with the new Unity Entity-Component-System and Data-Oriented-Tech-Stack."
    },
    {
        title: "Flexible",
        text: "Tween, punch and shake values of all built-in and custom components."
    },
    {
        title: "Performance",
        text: "Uses best code practice to achieve fast performance and low memory consumption."
    },
    {
        title: "Sequence",
        text: "Combine multiple tweens into one sequence."
    },
    {
        title: "Control",
        text: "Ease, loop, delay and pause tweens."
    }
]

export const Home: FC = () =>
{
    return (
        <Content>
            <Box ml="auto" mr="auto" maxWidth={800} padding={4}>
                <Grid container spacing={4} alignItems="center">
                    <Grid item xs="auto">
                        <img src={Splash} alt="" width={128} height={128} />
                    </Grid>
                    <Grid item xs>
                        <Typography variant="h4">
                            ECS Tween
                        </Typography>
                        <Typography variant="h6">
                            Tweening library for Unity Entity-Component-System
                        </Typography>
                        <br />
                        <ButtonGroup>
                            <Button href="http://u3d.as/1DHf" variant="outlined">
                                Download
                            </Button>
                            <Button component={NavLink} to="/docs" variant="outlined">
                                Get started
                            </Button>
                        </ButtonGroup>
                    </Grid>
                </Grid>
            </Box>
            <br />
            <Box ml="auto" mr="auto" maxWidth={800}>
                <Card>
                    <CardHeader
                        avatar={<Build />}
                        title={<CardHeaderTitle>Getting started</CardHeaderTitle>}
                    />
                    <CardContent>
                        <Title>1. Install ECS</Title>
                        <Typography gutterBottom>Download and import the following packages using Unity Package Manager:</Typography>
                        <Typography>
                            <i>
                                Entities 0.1.1-preview
                            </i>
                        </Typography>
                        <Typography>
                            <i>
                                Hybrid Renderer 0.1.1-preview (only needed for samples)
                            </i>
                        </Typography>
                    </CardContent>
                    <CardContent>
                        <Title>2. Start tweening</Title>
                        <CodeBlock>
                            {
                                `// Add target component to tween
entityManager.AddComponent<Translation>(entity);

// Add tween component 
entityManager.AddComponent<TweenTranslation>(entity);

// Set component data to move entity back and forth in an infinite loop
entityManager.SetComponentData(entity, CreateTween.Translation(
    from: new float3(-5f, 0f, 0f),
    to: new float3(5f, 0f, 0f),
    duration: 1f,
    ease: EaseType.InOutQuad,
    loops: -1,
    loopType: LoopType.PingPong
));`
                            }
                        </CodeBlock>
                    </CardContent>
                </Card>
                <br />
                <Card>
                    <CardHeader
                        avatar={<Star />}
                        title={<CardHeaderTitle>Features</CardHeaderTitle>}
                    />
                    <List>
                        {features.map(feature =>
                            (
                                <ListItem>
                                    <div>
                                        <Typography variant="body1">
                                            <b>* {feature.title}</b>
                                        </Typography>
                                        <Typography variant="body1">
                                            {feature.text}
                                        </Typography>
                                    </div>
                                </ListItem>
                            ))}
                    </List>
                </Card>
            </Box>
        </Content>
    );
}