import { Card, CardHeader, CardContent, Typography } from '@material-ui/core';
import React, { FC } from 'react';
import { CardHeaderTitle, CodeSample, CodeSamples } from '.';
import { supportedComponents } from '../data';

const samples = supportedComponents.map<CodeSample>(t => ({
    title: t.title,
    snippet: `var sequence${t.component}Component = Create.${t.component}Sequence(...);`,
    code: (
        `${t.component}Sequence sequence = Create.${t.component}Sequence(
    int loops, // Times to play the tween (use negative value for infinite loop)
    LoopMode loopMode, // Direction of the each loop (normal, reverse, ping-pong or reverse-ping-pong)
));

Tween${t.component}BufferElement bufferElement = Create.${t.component}TweenBufferElement(
    ... // Same parameters as regular Create.${t.component}Tween(...);
));

Tween${t.component}BufferElement bufferElement = Create.${t.component}PunchBufferElement(
    ... // Same parameters as regular Create.${t.component}Punch(...);
));`
    ),
    example: (
        `using ECSTween;
using Unity.Transforms;
using Unity.Entities;
using UnityEngine;

public class Bootstrap : MonoBehaviour
{
    private void Awake()
    {
        var entityManager = World.Active.EntityManager;

        var archetype = entityManager.CreateArchetype(
            typeof(${t.component}),
            typeof(Tween${t.component}),
            typeof(Sequence${t.component})
        );
        
        var entity = entityManager.CreateEntity(archetype);
        
        var entityBuffer = entityManager.AddBuffer<Tween${t.component}BufferElement>(entity);
        for(var i = 0; i < 10; i++)
        {
            entityBuffer.Add(Create.${t.component}TweenBufferElement(...));
            entityBuffer.Add(Create.${t.component}PunchBufferElement(...));
        }

        var sequence${t.component}Component = Create.${t.component}Sequence(...);
        entityManager.SetComponentData(entity, sequence${t.component}Component);
    }
}`
    )
}));

export const LearnSequence: FC = (props) =>
{
    return (
        <>
            <Card>
                <CardHeader
                    title={<CardHeaderTitle>Sequence</CardHeaderTitle>}
                />
                <CardContent>
                    <Typography gutterBottom>
                        A sequence is an array of tweens to be played in order.
                        It can only contain tweens of one type.
                        Sequences uses entity buffers to queue the tweens.
                        To make a sequence, add the corresponding tween component and sequence component of the target component.
                        Afterwards, add a buffer to the entity and start adding tween buffer elements.
                    </Typography>
                    <Typography>
                        <b>Note:</b> Currently does not support shaking.
                    </Typography>
                </CardContent>
                <CodeSamples samples={samples} />
            </Card>
        </>
    );
}