import React, { FC } from 'react';
import { Card, CardHeader, CardContent, Typography, Button, List, ListItem } from '@material-ui/core';
import { CardHeaderTitle, CodeBlock, Title } from '.';

export const GettingStarted: FC = () =>
{
	return (
		<Card>
			<CardHeader
				title={<CardHeaderTitle>Getting started</CardHeaderTitle>}
			/>
			<CardContent>
				<Title>1. Install ECS</Title>
				<Typography gutterBottom>Download and import the following packages using Unity Package Manager:</Typography>
				<Typography>
					<i>
						Entities 0.1.1-preview
                            </i>
				</Typography>
				<Typography>
					<i>
						Hybrid Renderer 0.1.1-preview (only needed for samples)
                            </i>
				</Typography>
			</CardContent>
			<CardContent>
				<Title>2. Install ECS Tween</Title>
				<Typography>Download and import the newest version of ECS Tween from the Asset Store.</Typography>
				<br />
				<Button href="http://u3d.as/1DHf" variant="outlined">
					Download
				</Button>
			</CardContent>
			<CardContent>
				<Title>3. Start tweening</Title>
				<CodeBlock>
					{
						`// Add target component to tween
entityManager.AddComponent<Translation>(entity);

// Add tween component 
entityManager.AddComponent<TweenTranslation>(entity);

// Set component data to move entity back and forth in an infinite loop
entityManager.SetComponentData(entity, CreateTween.Translation(
	from: new float3(-5f, 0f, 0f),
	to: new float3(5f, 0f, 0f),
	duration: 1f,
	ease: EaseType.InOutQuad,
	loops: -1,
	loopType: LoopType.PingPong
));`
					}
				</CodeBlock>
			</CardContent>
		</Card>
	);
}