import React, { FC } from 'react';
import { createMuiTheme, CssBaseline } from "@material-ui/core";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/styles"
import shadows from '@material-ui/core/styles/shadows';

const theme = createMuiTheme({
	palette: {
		type: 'dark',
	},
	overrides: {
		MuiAppBar: {
			root: {
				zIndex: 1300,
			},
			colorDefault: {
				backgroundColor: "#424242",
			}
		},
		MuiDrawer: {
			paperAnchorDockedLeft: {
				boxShadow: shadows[5],
				borderRightStyle: "none",
			}
		},
		MuiCardContent: {
			root: {
				"&:last-child": {
					paddingBottom: 16
				}
			}
		}
	}
});

export const ThemeProvider: FC = (props) =>
{
	return (
		<MuiThemeProvider theme={theme}>
			<CssBaseline />
			{props.children}
		</MuiThemeProvider>
	);
}