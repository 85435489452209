import React, { FC } from "react";
import SyntaxHighlighter from 'react-syntax-highlighter';
import { vs2015 as style } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { useTheme } from "@material-ui/styles";
import { Theme } from "@material-ui/core";

export const CodeBlock: FC = (props) => 
{
	const theme = useTheme<Theme>();

	return (
		<SyntaxHighlighter
			language="csharp"
			style={style}
			customStyle={{
				borderRadius: theme.shape.borderRadius,
				width: "100%",
			}}>
			{props.children}
		</SyntaxHighlighter>
	);
}