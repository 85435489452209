import { Box, Collapse, Grid, IconButton, List, ListItem, Typography, Divider } from "@material-ui/core";
import Open from '@material-ui/icons/ChevronRight';
import Close from '@material-ui/icons/ExpandMore';
import React, { FC, useState } from "react";
import { CodeBlock, Title } from ".";

export interface CodeSample
{
    title: string;
    snippet?: string;
    code?: string;
    example?: string;
}

export interface CodeSamplesProps 
{
    samples: CodeSample[];
}

export const CodeSamples: FC<CodeSamplesProps> = (props) => 
{
    const [open, setOpen] = useState<number>();
    return (
        <List disablePadding>
            {props.samples.map((sample, i) =>
            {
                const isOpen = open === i;
                // const isFirst = i === 0;
                // const isLast = i === props.samples.length - 1;

                return (
                    <>
                        <Divider />
                        <ListItem key={i}>
                            <Box width="100%">
                                <Grid container alignItems="center">
                                    <Grid item xs={12} md={3}>
                                        <IconButton onClick={() => i === open ? setOpen(undefined) : setOpen(i)}>
                                            {isOpen ? <Close /> : <Open />}
                                        </IconButton>
                                        {sample.title}
                                    </Grid>
                                    {sample.snippet &&
                                        <Grid item xs={12} md={9}>
                                            <CodeBlock>
                                                {sample.snippet}
                                            </CodeBlock>
                                        </Grid>
                                    }
                                </Grid>
                                <Collapse in={isOpen} unmountOnExit>
                                    <br />
                                    <Title>Parameters</Title>
                                    <CodeBlock>
                                        {sample.code}
                                    </CodeBlock>
                                    <br />
                                    <Title>Example</Title>
                                    <CodeBlock>
                                        {sample.example}
                                    </CodeBlock>
                                </Collapse>
                            </Box>
                        </ListItem>
                    </>
                )
            })}
        </List>
    );
}