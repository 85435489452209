import { Card, CardHeader, CardContent, Typography } from '@material-ui/core';
import React, { FC } from 'react';
import { CardHeaderTitle, CodeSample, CodeSamples } from '.';
import { supportedComponents } from '../data';

const samples = supportedComponents.map<CodeSample>(t => ({
    title: t.title,
    snippet: `var shake${t.component}Component = Create.${t.component}Shake(...);`,
    code: (
        `Shake${t.component} shake = Create.${t.component}Shake(
    ${t.valueType} at, // Start value (or end value depending on the fade parameter)
    float duration, // Duration in seconds (negative value for infinite duration)
    ${t.valueType} strength, // Min and max values of the shake.
    int vibrations, // Vibrations per second.
    Fade fade // Fading function
));`
    ),
    example: (
        `using ECSTween;
using Unity.Entities;
using Unity.Transforms;
using UnityEngine;

public class Bootstrap : MonoBehaviour
{
    private void Awake()
    {
        var entityManager = World.Active.EntityManager;

        var archetype = entityManager.CreateArchetype(
            typeof(${t.component}),
            typeof(Shake${t.component})
        );
        
        var entity = entityManager.CreateEntity(archetype);

        var shake${t.component}Component = Create.${t.component}Shake(...);
        
        entityManager.SetComponentData(entity, shake${t.component}Component);
    }
}`
    )
}));

export const LearnShakes: FC = (props) =>
{
    return (
        <>
            <Card>
                <CardHeader
                    title={<CardHeaderTitle>Shake</CardHeaderTitle>}
                />
                <CardContent>
                    <Typography gutterBottom>
                        To shake the value of any component on an entity, add the corresponding shake component to the same entity.
                        Shaking is using a completely different set of components and systems.
                    </Typography>
                    <Typography>
                        <b>Note:</b> Currently not supported by sequences.
                    </Typography>
                </CardContent>
                <CodeSamples samples={samples} />
            </Card>
        </>
    );
}