import React, { FC } from 'react';
import { Card, CardContent, CardHeader, Typography } from '@material-ui/core';
import { CodeBlock, Title } from '.';
import { CardHeaderTitle } from './CardHeaderTItle';

export const LearnCustomTween: FC = (props) =>
{
    return (
        <>
            <Card>
                <CardHeader
                    title={<CardHeaderTitle>Custom tween</CardHeaderTitle>}
                />
                <CardContent>
                    <Title>1. Define custom component</Title>
                    <CodeBlock>
                        {`using Unity.Entities;

public struct CustomComponent : IComponentData
{
    public int Value;
}`}
                    </CodeBlock>
                    <br />
                    <Title>2. Define custom tween component</Title>
                    <CodeBlock>
{`using System.Runtime.CompilerServices;
using ECSTween;
using Unity.Mathematics;

public struct CustomTween : ITween<CustomComponent, int>
{
    public TweenData<int> Value { get; set; }
    
    [MethodImpl(MethodImplOptions.AggressiveInlining)]   
    public CustomComponent Mutate(CustomComponent target, int from, int to, float lerp)
    {
        return new CustomComponent
        {
            Value = (int)math.lerp(from, to, lerp)
        };
    }
}`}
                    </CodeBlock>
                    <br />
                    <Title>3. Define custom tween system</Title>
                    <CodeBlock>
{`using ECSTween;

public class CustomTweenSystem : TweenSystem<CustomTween, CustomComponent, int> {}`}
                    </CodeBlock>
                    <br />
                    <Title>4. Use custom tween</Title>
                    <CodeBlock>
{`using Unity.Entities;
using UnityEngine;

public class Bootstrap : MonoBehaviour
{
    private void Awake()
    {
        var entityManager = World.Active.EntityManager;

        var archetype = entityManager.CreateArchetype(
            typeof(CustomComponent),
            typeof(CustomTween),
        );
        
        var entity = entityManager.CreateEntity(archetype);
        entityManager.SetComponentData(entity, new CustomTween
        {
            Value = new TweenData<int>{...}
        });
    }
}`}
                    </CodeBlock>
                </CardContent>
            </Card>
        </>
    );
}