import { Card, CardHeader, CardContent, Typography } from '@material-ui/core';
import React, { FC } from 'react';
import { CardHeaderTitle, CodeSample, CodeSamples } from '.';
import { supportedComponents } from '../data';

const samples = supportedComponents.map<CodeSample>(t => ({
    title: t.title,
    snippet: `var punch${t.component}Component = Create.${t.component}Punch(...);`,
    code: (
`Tween${t.component} punch = Create.${t.component}Punch(
    ${t.valueType} from, // Starting value
    ${t.valueType} strength, // Added to the starting value
    float duration, // Duration in seconds
    Elasticity ease, // Level of bounciness
    int loops, // Times to play the punch tween (use negative value for infinite loop)
    float delay, // Time in seconds before the tweening begins in each loop
    float delayAfter // Time in seconds to wait after the tween in each loop
));`
    ),
    example: (
        `using ECSTween;
using Unity.Entities;
using Unity.Transforms;
using UnityEngine;

public class Bootstrap : MonoBehaviour
{
    private void Awake()
    {
        var entityManager = World.Active.EntityManager;

        var archetype = entityManager.CreateArchetype(
            typeof(${t.component}),
            typeof(Tween${t.component})
        );
        
        var entity = entityManager.CreateEntity(archetype);

        var punch${t.component}Component = Create.${t.component}Punch(...);
        
        entityManager.SetComponentData(entity, punch${t.component}Component);
    }
}`
    )
}));

export const LearnPunch: FC = (props) =>
{
    return (
        <>
            <Card>
                <CardHeader
                    title={<CardHeaderTitle>Punch</CardHeaderTitle>}
                />
                <CardContent>
                    <Typography>
                        To punch the value of any component on an entity, add the corresponding tween component to the same entity.
                        Punching is the same as tweening, but using a special ease function that will return the target value back to the 'from' value.
                    </Typography>
                </CardContent>
                <CodeSamples samples={samples} />
            </Card>
        </>
    );
}